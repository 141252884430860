<template>
  <div class="row items-center">
    <div class="q-mr-md circle row justify-center">
      <p class="circle1">{{ user.name }}</p>
      <p class="circle2">{{ user.last_name }}</p>
      <q-tooltip anchor="bottom middle" self="top middle">
        {{ user.name }} {{ user.last_name }}
      </q-tooltip> 
    </div>
    <!-- <div class="q-ml-md">
      <p class="q-mb-sm circle-text text-left text-weight-bold text-body-1">
        {{ user.name }} {{ user.last_name }}
      </p>
      <p class="q-mb-sm">{{ user.email }}</p>
    </div> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import settings from "../../../../mixins/settings";

export default {
  mixins: [settings],

  computed: {
    ...mapState("auth", ["user"]),
  },
};
</script>

<style lang="scss" scoped>
.circle {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  font-size: 10px;
  color: #fff;
  text-align: center;
  background: v-bind("settings.color_accent");
  font-weight: 600;
}

.circle1 {
  font-size: 0;
}

.circle1:first-letter {
  font-size: 15px !important;
  color: white !important;
  text-transform: uppercase;
}

.circle2 {
  font-size: 0;
}

.circle2:first-letter {
  font-size: 15px !important;
  color: white !important;
  text-transform: uppercase;
}

.circle-text, .q-tooltip {
  text-transform: capitalize;
}
</style>
